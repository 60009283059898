import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Wrapper = styled.div`
  padding: 20px 90px;
  @media only screen and (max-width: ${props =>
      props.theme.responsive.medium}) {
    padding: 0 70px 70px;
  }
  h2 {
    color: ${props => props.theme.colors.text.dark};
    width: 80%;
    font-size: 25px;
    line-height: 35px;
    font-weight: 700;
    text-transform: uppercase;
    @media only screen and (max-width: ${props =>
        props.theme.responsive.small}) {
      width: 100%;
      padding-bottom: 15px;
      font-size: 22px;
      line-height: 25px;
    }
  }
  p {
    text-align: justify;
    margin-bottom: 20px;
  }
  a {
    margin-top: 20px;
  }
`;

const Left = ({ contentSection }) => (
  <Wrapper>
    <h2>{contentSection.title}</h2>
    <span
      dangerouslySetInnerHTML={{
        __html: contentSection.text.childMarkdownRemark.html
      }}
    />
    {contentSection.link && (
      <Link
        className="button"
        to={`/${contentSection.link[0].slug}/`}
        title={contentSection.link[0].menuTitle}
      >
        {contentSection.link[0].pageTitle}
      </Link>
    )}
  </Wrapper>
);

export default Left;
