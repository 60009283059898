import React from 'react';
import Img from 'gatsby-image';

// Components
import { Wrapper, Inner, Left } from './files';

const FlexBox = ({ contentSection, first, last, reversed }) => (
  <Wrapper className={`${first ? 'first' : ''} ${last ? 'last' : ''}`}>
    <Inner className={reversed ? 'reversed' : ''}>
      <Left contentSection={contentSection} />
      <Img
        fluid={contentSection.image.fluid}
        alt={contentSection.image.title}
      />
    </Inner>
  </Wrapper>
);

export default FlexBox;
