import styled from 'styled-components';

const Wrapper = styled.section`
  height: 520px;
  position: relative;
  transition: all 0.2s;
  @media only screen and (max-width: ${(props) =>
      props.theme.responsive.medium}) {
    height: 380px;
  }
  &.modal-page {
    margin-top: 0;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
    border-bottom: 5px solid ${(props) => props.theme.colors.primary};
    z-index: 1;
  }
  &::after {
    clear: both;
    content: '';
    display: table;
  }
  * {
    box-sizing: border-box;
  }
`;

export default Wrapper;
