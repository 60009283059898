import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  padding-top: 35px;
  padding-bottom: 35px;
  @media only screen and (max-width: ${(props) =>
      props.theme.responsive.medium}) {
    padding-top: 70px;
    padding-bottom: 0;
  }
  &::after {
    clear: both;
    content: '';
    display: table;
  }
  * {
    box-sizing: border-box;
  }
  &.first {
    padding-top: 70px;
  }
  &.last {
    padding-bottom: 70px;
    @media only screen and (max-width: ${(props) =>
        props.theme.responsive.medium}) {
      padding-bottom: 0;
    }
  }
`;

export default Wrapper;
