import React from 'react';

// Components
import { Wrapper, Image, Title } from './files';

const HeroImage = ({ seoContent, isRoom, modal, nodeImage, createdNode }) => {
  const { pageTitle, featuredImage } = seoContent;
  return (
    <Wrapper className={modal ? 'modal-page' : ''}>
      <Image featuredImage={createdNode ? nodeImage : featuredImage} />
      <Title
        createdNode={createdNode}
        modal={modal}
        pageTitle={pageTitle}
        isRoom={isRoom}
      />
    </Wrapper>
  );
};

export default HeroImage;
