import React from 'react';
import styled from 'styled-components';

// Context
import ContextConsumer from '../../../contextProvider';

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  > span {
    max-width: 1160px;
    width: 100%;
    padding: 32px;
    margin: 0 auto;
    h1 {
      max-width: 550px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: ${props => props.theme.colors.text.light};
      text-transform: uppercase;
    }
    button {
      font-size: 14px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: center;
      color: ${props => props.theme.colors.text.light};
      background: transparent;
      min-width: 220px;
      height: 52px;
      border-radius: 4px;
      border: solid 2px ${props => props.theme.colors.text.light};
      display: inline-block;
      text-align: center;
      @media only screen and (max-width: ${props =>
          props.theme.responsive.medium}) {
        font-size: 12px;
        min-width: 180px;
        height: 40px;
        line-height: 38px;
      }
      &:hover {
        background: ${props => props.theme.colors.primary};
        border-color: ${props => props.theme.colors.primary};
      }
      img {
        position: absolute;
        left: 40px;
        top: 50%;
        transform: translateY(-50%);
        margin-right: 10px;
        width: 20px;
        height: 20px;
        @media only screen and (max-width: ${props =>
            props.theme.responsive.medium}) {
          width: 15px;
          height: 15px;
          left: 30px;
        }
      }
      span {
        margin-left: 30px;
      }
    }
  }
  &.event {
    align-items: flex-end;
    h1 {
      max-width: 800px;
      font-size: 50px;
      @media only screen and (max-width: ${props =>
          props.theme.responsive.medium}) {
        font-size: 25px;
      }
    }
  }
`;

const Title = ({ createdNode, pageTitle, isRoom, modal }) => (
  <ContextConsumer>
    {({ toggleRoomModalOpen }) => (
      <Wrapper className={createdNode ? 'event' : ''}>
        <span>
          <h1>{pageTitle}</h1>
          {isRoom && !modal && (
            <button onClick={toggleRoomModalOpen}>
              <img
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgY2xhc3M9IiI+PGc+PGc+CiAgICA8Zz4KICAgICAgPHBhdGggZD0ibTEwLjUsNTguOWg0NC4zYzIuMywwIDQuMS0xLjggNC4xLTQuMXYtNDQuM2MwLTIuMy0xLjgtNC4xLTQuMS00LjFoLTQ0LjNjLTIuMywwLTQuMSwxLjgtNC4xLDQuMXY0NC4zYzAsMi4yIDEuOSw0LjEgNC4xLDQuMXptNC4xLTQ0LjNoMzYuMXYzNi4xaC0zNi4xdi0zNi4xeiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkRGQkZCIiBkYXRhLW9sZF9jb2xvcj0iI0ZERkJGQiI+PC9wYXRoPgogICAgICA8cGF0aCBkPSJtMTIyLjYsMTAuNWMwLTIuMy0xLjgtNC4xLTQuMS00LjFoLTQ0LjNjLTIuMywwLTQuMSwxLjgtNC4xLDQuMXY0NC4zYzAsMi4zIDEuOCw0LjEgNC4xLDQuMWg0NC4zYzIuMywwIDQuMS0xLjggNC4xLTQuMXYtNDQuM3ptLTguMiw0MC4yaC0zNi4xdi0zNi4xaDM2LjF2MzYuMXoiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIGNsYXNzPSJhY3RpdmUtcGF0aCIgc3R5bGU9ImZpbGw6I0ZERkJGQiIgZGF0YS1vbGRfY29sb3I9IiNGREZCRkIiPjwvcGF0aD4KICAgICAgPHBhdGggZD0ibTEwLjUsMTIyLjZoNDQuM2MyLjMsMCA0LjEtMS44IDQuMS00LjF2LTQ0LjNjMC0yLjMtMS44LTQuMS00LjEtNC4xaC00NC4zYy0yLjMsMC00LjEsMS44LTQuMSw0LjF2NDQuM2MwLDIuMiAxLjksNC4xIDQuMSw0LjF6bTQuMS00NC4zaDM2LjF2MzYuMWgtMzYuMXYtMzYuMXoiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIGNsYXNzPSJhY3RpdmUtcGF0aCIgc3R5bGU9ImZpbGw6I0ZERkJGQiIgZGF0YS1vbGRfY29sb3I9IiNGREZCRkIiPjwvcGF0aD4KICAgICAgPHBhdGggZD0ibTExOC41LDcwLjFoLTQ0LjNjLTIuMywwLTQuMSwxLjgtNC4xLDQuMXY0NC4zYzAsMi4zIDEuOCw0LjEgNC4xLDQuMWg0NC4zYzIuMywwIDQuMS0xLjggNC4xLTQuMXYtNDQuM2MwLTIuMi0xLjktNC4xLTQuMS00LjF6bS00LjEsNDQuM2gtMzYuMXYtMzYuMWgzNi4xdjM2LjF6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iYWN0aXZlLXBhdGgiIHN0eWxlPSJmaWxsOiNGREZCRkIiIGRhdGEtb2xkX2NvbG9yPSIjRkRGQkZCIj48L3BhdGg+CiAgICA8L2c+CiAgPC9nPjwvZz4gPC9zdmc+"
                alt="Open Gallery"
              />
              <span>View Gallery</span>
            </button>
          )}
        </span>
      </Wrapper>
    )}
  </ContextConsumer>
);

export default Title;
